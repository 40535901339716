import BigNumber from "bignumber.js";

// 필요 없는 0 제거
function removeUnusedZero(value, decimals) {
  if (value && decimals) {
    BigNumber.config({
      DECIMAL_PLACES: decimals,
    });

    return new BigNumber(value).toFixed();
  } else return value;
}

// 소숫점 이하의 자릿수가 digits을 '초과'하면 초과분을 생략하고 ...을 붙힌다.
function setVisualPrice(number, DISPLAYING_DIGITS) {
  let result;
  const split = String(number).split(".");

  if (split && split[1]) {
    const NUMBER_OF_DECIMALS = split[1].substring(DISPLAYING_DIGITS);

    if (NUMBER_OF_DECIMALS === "") {
      result = split[0] + "." + split[1];
    } else {
      result =
        split[0] + "." + split[1].substring(0, DISPLAYING_DIGITS) + "...";
    }
  } else {
    result = split[0];
  }

  // 맨 왼쪽 숫자가 0이면 제거
  result = result.replace(/(^0+)/, "");
  result.split(".")[0] === "" ? (result = "0" + result) : result;

  return result;
}

function handleZeroParula(number, decimals) {
  let result;
  const split = String(number).split(".");

  if (split && split[1]) {
    result = split[0] + split[1].padEnd(decimals, "0");
  } else result = String(number) + "0".repeat(decimals);

  return result.replace(/(^0+)/, "");
}

function handleZeroDB(number, decimals) {
  let result;
  const split = String(number).split(".");

  if (split && split[1]) {
    result = split[0] + "." + split[1].padEnd(decimals, "0");
  } else result = String(number) + "." + "0".repeat(decimals);

  result = result.replace(/(^0+)/, "");
  result.split(".")[0] === "" ? (result = "0" + result) : result;

  return result;
}

// small < large -> 1, small === large -> 0, small > large -> -1
function bigNumberCompare(small, large) {
  console.log(small, large);

  const small_value = new BigNumber(small);
  const large_value = new BigNumber(large);

  return small_value.comparedTo(large_value);
}

// ex) 7000000000000000000, 18 => 7.000000000000000000
function weiValueToDB(weiValue, decimals) {
  const { length } = weiValue;

  if ((!weiValue && !length) || !decimals) {
    return weiValue;
  }

  if (length > decimals) {
    return `${weiValue.slice(0, -decimals)}.${weiValue.slice(
      length - decimals
    )}`;
  } else {
    return `0.${"0".repeat(decimals - length)}${weiValue}`;
  }
}

export {
  removeUnusedZero,
  handleZeroParula,
  handleZeroDB,
  bigNumberCompare,
  setVisualPrice,
  weiValueToDB,
};
